@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    overscroll-behavior-y: none;
    font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000; /* Black base */
    color: #f5f5f7;
  }
}

@layer components {
  .apple-button {
    @apply rounded-full px-5 py-3 font-medium transition-all duration-300 
           bg-sortio-primary text-white hover:opacity-90
           shadow-md hover:shadow-lg focus:outline-none;
  }
  
  .business-button {
    @apply rounded-lg px-4 py-1.5 font-semibold transition-all duration-300
           border border-sortio-secondary/70 text-sortio-secondary hover:bg-sortio-secondary/10
           shadow-sm hover:shadow-md focus:outline-none;
  }
  
  .apple-panel {
    @apply bg-apple-700/80 backdrop-blur-lg rounded-apple 
           shadow-apple border border-apple-600/40;
  }
  
  .apple-gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-sortio-primary to-sortio-secondary;
  }
  
  .apple-glass-bg {
    @apply bg-apple-700/80 backdrop-blur-xl;
  }
  
  .business-glass-bg {
    @apply bg-slate-900/80 backdrop-blur-xl;
  }

  .apple-section {
    @apply py-16 sm:py-20 px-6;
  }
  
  .business-cta-button {
    @apply rounded-full px-5 py-2 font-medium transition-all duration-300 
           bg-blue-600 text-white hover:bg-blue-700
           shadow-md hover:shadow-lg focus:outline-none;
  }
  
  .personal-button {
    @apply rounded-lg px-4 py-1.5 font-semibold transition-all duration-300
           border border-sortio-primary/70 text-sortio-primary hover:bg-sortio-primary/10
           shadow-sm hover:shadow-md focus:outline-none;
  }
  
  /* Custom Scrollbar - Apple Style */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(110, 110, 115, 0.4) transparent;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(110, 110, 115, 0.4);
    border-radius: 10px;
    transition: all 0.3s ease;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(110, 110, 115, 0.6);
  }
}